
body {
  background-color: rgba(0,0,0,0.1)
}
.app-item {
  background-color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 2px;
  box-shadow: 0 3px 2px rgba(0,0,0,0.05);
  border: solid rgba(0,0,0,0.2) 1px;
}
.app-item:hover {
  box-shadow: 0 3px 2px rgba(0,0,0,0.1);
  border: solid rgba(0,0,0,0.4) 1px;
  transition: all 0.3s;
}

input, input[type="password"] {
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

textarea {

  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

select {
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  /* padding-top: 12px; */
  /* padding-bottom: 12px; */
  margin-bottom: 20px;
}
.select-item:hover {
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
  background-color: rgba(0,0,0,0.05);
}

.cancel-item {
  cursor: pointer;
  font-size: 125%;
  color: grey;
  font-weight: 600;
}


#dashboard {
  background-color: #e4e4e4;
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
}

.material-icons {
  /* font-size: 160%; */
  margin-right: 5px;
}

.alert-thing {
  animation-duration: 1.2s;
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  color: maroon;
}
@keyframes blink {
  from {
     opacity: 0.1;
  }
  to {
     opacity: 1;
  }
}
a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.button {
  background-color: #324b6f;
  color: white;
  display: flex;
  justify-content: center;
  font-weight: 800;
  padding: 10px 20px;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  cursor: pointer;

}
