.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.4);
  right: 0px;
}

/* Modal Content/Box */
.modal-content {
  border-radius: 2px;
  background-color: #fefefe;
  z-index: 500;
  padding: 20px;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 30vh;
  /* position: fixed; */
  position: relative;
  width: 65%;
  max-height: 90vh;
	min-width: 600px;
  overflow: auto;
}

/* The Close Button */
.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 14px;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: radial-gradient(rgba(20, 20, 20,.3), rgba(0, 0, 0, .3)); */
  /* background: -webkit-radial-gradient(rgba(20, 20, 20,.3), rgba(0, 0, 0,.3)); */
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0,0,0, 0.75) 1.5em 0 0 0, rgba(0,0,0, 0.75) 1.1em 1.1em 0 0, rgba(0,0,0, 0.75) 0 1.5em 0 0, rgba(0,0,0, 0.75) -1.1em 1.1em 0 0, rgba(0,0,0, 0.75) -1.5em 0 0 0, rgba(0,0,0, 0.75) -1.1em -1.1em 0 0, rgba(0,0,0, 0.75) 0 -1.5em 0 0, rgba(0,0,0, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(0,0,0, 0.75) 1.5em 0 0 0, rgba(0,0,0, 0.75) 1.1em 1.1em 0 0, rgba(0,0,0, 0.75) 0 1.5em 0 0, rgba(0,0,0, 0.75) -1.1em 1.1em 0 0, rgba(0,0,0, 0.75) -1.5em 0 0 0, rgba(0,0,0, 0.75) -1.1em -1.1em 0 0, rgba(0,0,0, 0.75) 0 -1.5em 0 0, rgba(0,0,0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}






.tgl {
	  display: none;
	}
	.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
		  box-sizing: border-box;
		}
		.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
			  background: none;
			}
			.tgl + .tgl-btn {
				  outline: 0;
					  display: block;
						  width: 4em;
							  height: 2em;
								  position: relative;
									  cursor: pointer;
										  user-select: none;
										}
										.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
											  position: relative;
												  display: block;
													  content: "";
														  width: 50%;
															  height: 100%;
															}
															.tgl + .tgl-btn:after {
																  left: 0;
																}
																.tgl + .tgl-btn:before {
																	  display: none;
																	}
																	.tgl:checked + .tgl-btn:after {
																		  left: 50%;
																		}

																		.tgl-light + .tgl-btn {
																			  background: #e3e3e3;
																				  border-radius: 2em;
																					  padding: 2px;
																						  transition: all 0.4s ease;
																						}
																						.tgl-light + .tgl-btn:after {
																							  border-radius: 50%;
																								  background: #fff;
																									  transition: all 0.2s ease;
																									}
																									.tgl-light:checked + .tgl-btn {
																										  background: #9FD6AE;
																										}

